import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import BlogBink, { BlogBinkPosts } from '@ubo/blog-bink'

// Layout
import GridSecondary from 'components/flex/Posts/Layout/GridSecondary'

// Interface
import { PostsProps } from 'components/flex/Posts/Overview/Shell'

const Vacancies: React.FC<PostsProps> = ({ fields }) => {
  const {
    allWpVacancy,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.vacanciesQueryQuery>(graphql`
    query vacanciesQuery {
      allWpVacancy(sort: { order: DESC, fields: date }) {
        edges {
          node {
            ...generalVacancyFragment
          }
        }
      }
    }
  `)

  const posts: unknown = allWpVacancy.edges

  return (
    <section className="my-5 pb-lg-5">
      <BlogBink
        posts={posts as BlogBinkPosts}
        id="vacancies"
        limit={Number(fields.limit)}
      >
        <div className="container">
          <GridSecondary fields={fields} />
        </div>
      </BlogBink>
    </section>
  )
}

export default Vacancies
