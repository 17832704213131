import React from 'react'
import styled from 'styled-components'

// Components
import AnchorPrimary from 'components/elements/Anchors/AnchorPrimary'
import ParseContent from 'components/shared/ParseContent'

// Images
import Pointer from 'img/location.inline.svg'

// Interface
import { LatestPostsProps } from 'components/flex/Posts/Latest/Shell'

const Wrapper = styled.div`
  border-radius: 24px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
`
const Label = styled.div`
  background: linear-gradient(34.64deg, #bd1622 18.71%, #f27400 84.69%);
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  color: ${({ theme }) => theme.color.light};
  font-family: ${({ theme }) => theme.font.family.secondary};

  & span {
    @media (min-width: 992px) {
      font-size: 18px;
    }
  }

  & h2 {
    @media (min-width: 992px) {
      font-size: 25px;
    }
  }

  &:before {
    content: '';
    position: absolute;
    right: 100%;
    top: 0px;
    bottom: 0px;
    width: 220px;
    background-color: ${({ theme }) => theme.color.primary};
  }
`

const Content = styled(ParseContent)`
  & p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }
`

const PostSecondary: React.FC<LatestPostsProps> = ({ post, fields }) => (
  <Wrapper className="p-4 p-sm-5 mb-5 overflow-hidden">
    <div className="row">
      <div className="col-lg-6">
        <Label className="position-relative p-3">
          <div className="d-flex align-items-center">
            <Pointer />
            <span className="ms-3">{post.recap.location}</span>
          </div>
          <h2 className="mt-2">{post.title}</h2>
        </Label>
      </div>
    </div>

    <div className="row align-items-center mt-4">
      <div className="col-md-8">
        <Content content={post.recap.excerpt} className="ps-sm-4" />
      </div>
      <div className="col-md-4 d-flex justify-content-end mt-4 mt-md-0">
        <AnchorPrimary type="contrast" to={post.uri}>
          {fields.readmoretext}
        </AnchorPrimary>
      </div>
    </div>
  </Wrapper>
)

export default PostSecondary
