/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from 'styled-components'
import { useBlogBink } from '@ubo/blog-bink'

// Layout
import PostPrimary from 'components/flex/Posts/Layout/PostPrimary'

// Components
import ParseContent from 'components/shared/ParseContent'

// Interface
import { PostsProps } from 'components/flex/Posts/Overview/Shell'

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    @media (min-width: 992px) {
      font-size: 50px;
      line-height: 55px;
    }
  }
`

const DefaultGrid: React.FC<PostsProps> = ({ fields }) => {
  const blogBink = useBlogBink()

  return (
    <div className="row justify-content-center">
      <Content content={fields.description} className="mb-5" />
      <div className="col-lg-11">
        <div className="row">
          {blogBink.posts.length > 0 &&
            blogBink.posts.map((post, index) => (
              <div className="col-sm-6 col-lg-4 mb-4" key={index}>
                {/* @ts-ignore */}
                <PostPrimary post={post.node} fields={fields} />
              </div>
            ))}
          {blogBink.posts.length < 1 && (
            <div>
              <h2>
                Helaas, we kunnen op dit moment geen projecten uit deze
                categorie tonen, selecteer een andere categorie of probeer het
                laten nog eens.
              </h2>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default DefaultGrid
