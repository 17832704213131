/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from 'styled-components'
import { useBlogBink } from '@ubo/blog-bink'

// Layout
import PostSecondary from 'components/flex/Posts/Layout/PostSecondary'

// Components
import ParseContent from 'components/shared/ParseContent'

// Interface
import { PostsProps } from 'components/flex/Posts/Overview/Shell'

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    @media (min-width: 992px) {
      font-size: 50px;
      line-height: 55px;
    }
  }
`

const GridSecondary: React.FC<PostsProps> = ({ fields }) => {
  const blogBink = useBlogBink()

  return (
    <div className="row justify-content-center">
      <Content content={fields.description} className="mb-5" />
      <div className="col-lg-10">
        <div className="row">
          {blogBink.posts.map((post, index) => (
            <React.Fragment key={index}>
              {/* @ts-ignore */}
              <PostSecondary post={post.node} fields={fields} />
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  )
}

export default GridSecondary
