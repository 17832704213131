/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from 'styled-components'
import { useBlogBink } from '@ubo/blog-bink'

// Components
import ParseContent from 'components/shared/ParseContent'

// Layout
import PostPrimary from 'components/flex/Posts/Layout/PostPrimary'

// Interface
import { PostsProps } from 'components/flex/Posts/Overview/Shell'

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    @media (min-width: 992px) {
      font-size: 50px;
      line-height: 55px;
    }
  }
`

const GridPrimaryAlternate: React.FC<PostsProps> = ({ fields }) => {
  const blogBink = useBlogBink()

  let count = 0

  return (
    <div className="row">
      <Content content={fields.description} className="mb-5" />
      {blogBink.posts.map((post, index) => {
        let col = 'col-sm-4'

        if (count % 6 === 0) {
          col = 'col-sm-8'
        }

        if (count === 6) {
          // Dark Magician X
          count = -1
        }

        count += 1

        return (
          <div className={`col-6 ${col} mb-4`}>
            {/* @ts-ignore */}
            <PostPrimary key={index} post={post.node} fields={fields} />
          </div>
        )
      })}
    </div>
  )
}

export default GridPrimaryAlternate
